.img-fluid {
  max-width: 100%;
  height: auto;
}

.obj-cover {
  object-fit: cover;
}

.obj-contain {
  object-fit: contain;
}

.c-pointer {
  cursor: pointer;
}

.bg-blur {
  backdrop-filter: blur(20px);
}

// z index
.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-15 {
  z-index: 15;
}

.z-index-top {
  z-index: 2000;
}

// position
.pos-inherit {
  position: inherit;
}

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

// Gap
.g-3 {
  gap: 3px;
}

.g-5 {
  gap: 5px;
}

.g-8 {
  gap: 8px;
}

.g-10 {
  gap: 10px;
}

.g-15 {
  gap: 15px;
}

.g-20 {
  gap: 20px;
}

// Padding
.pt-0 {
  padding-top: 0px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.p-0 {
  padding: 0px !important;
}

.p-2 {
  padding: 2px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.p-50 {
  padding: 50px;
}

.p-60 {
  padding: 60px;
}

.p-70 {
  padding: 70px;
}

.p-80 {
  padding: 80px;
}

.p-90 {
  padding: 90px;
}

.p-100 {
  padding: 100px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-2 {
  padding-right: 2px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

// Animation
.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both !important;
}

// Border
.border-none {
  border: none !important;
}

.border-left {
  border-left: 5px double var(--primary-color);
  padding-left: 8px;
}

.border-right {
  border-right: 5px double var(--primary-color);
  padding-right: 8px;
}

.border-bottom {
  border-bottom: 5px double var(--primary-color);
}

.bb-warning {
  border-bottom: 5px double var(--warning-color);
}

.bs-secondary {
  border: 1px solid var(--grayXS-color) !important;
}

.bs-primary {
  border: 1px solid var(--primarySM-color) !important;
}

.br-6 {
  border-radius: 0.6rem !important;
}

.br-7 {
  border-radius: 0.7rem !important;
}

.br-8 {
  border-radius: 0.8rem !important;
}

.br-9 {
  border-radius: 0.9rem !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-15 {
  border-radius: 15px !important;
}

.br-20 {
  border-radius: 20px !important;
}

.br-30 {
  border-radius: 30px !important;
}

.br-50pr {
  border-radius: 50%;
}

// Text
.pre-wrap {
  white-space: pre-wrap !important;
}

.tx-xs {
  letter-spacing: 0.3px;
  font-size: 11px;
}

.tx-sm {
  letter-spacing: 0.3px;
  color: var(--dark-color);

  @media (max-width: 499px) {
    font-size: 14px;
  }

  @media screen and (min-width: 500px) {
    font-size: 16px;
  }
}

.link {
  text-decoration: none;
  color: var(--gray-color) !important;

  &:hover {
    cursor: pointer;
    color: var(--primary-color) !important;
  }
}

.p {
  color: var(--gray-color);
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 18pt;

  @media (max-width: 499px) {
    font-size: 14px;
  }

  @media screen and (min-width: 500px) {
    font-size: 16px;
  }
}

.h {
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: var(--dark-color);
}

.h1 {
  @media (max-width: 499px) {
    font-size: 26px;
  }

  @media screen and (min-width: 500px) {
    font-size: 32px;
  }

  @media screen and (min-width: 980px) {
    font-size: 40px;
  }
}

.h2 {
  @media (max-width: 499px) {
    font-size: 22px;
  }

  @media screen and (min-width: 500px) {
    font-size: 26px;
  }

  @media screen and (min-width: 980px) {
    font-size: 30px;
  }
}

.tx-trans-none {
  text-transform: none;
}

.tx-upp {
  text-transform: uppercase;
}

.tx-justify {
  text-align: justify;
}

.tx-center {
  text-align: center;
}

.tx-left {
  text-align: left;
}

.tx-right {
  text-align: right;
}

.tx-und {
  text-decoration: underline;
}

.tx-und-none {
  text-decoration: none;
}

//Display
.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.d-grid {
  display: grid;
}

.d-inline-flex {
  display: inline-flex;
}

.d-contents {
  display: contents !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.j-between {
  justify-content: space-between !important;
}

.j-evenly {
  justify-content: space-evenly;
}

.j-center {
  justify-content: center !important;
}

.j-start {
  justify-content: start;
}

.j-end {
  justify-content: end;
}

.a-center {
  align-items: center;
}

.a-start {
  align-items: flex-start;
}

.a-end {
  align-items: flex-end;
}

.overflow-auto {
  overflow: auto;
}

// Height
.h-inherit {
  height: inherit;
}

.h-10 {
  height: 10px;
}

.h-15 {
  height: 15px;
}

.h-20 {
  height: 20px;
}

.h-25 {
  height: 25px !important;
}

.h-30 {
  height: 30px;
}

.h-35 {
  height: 35px;
}

.h-40 {
  height: 40px;
}

.h-45 {
  height: 45px;
}

.h-50 {
  height: 50px;
}

.h-55 {
  height: 55px;
}

.h-60 {
  height: 60px;
}

.h-65 {
  height: 65px;
}

.h-70 {
  height: 70px;
}

.h-75 {
  height: 75px;
}

.h-80 {
  height: 80px;
}

.h-85 {
  height: 85px;
}

.h-90 {
  height: 90px;
}

.h-95 {
  height: 95px;
}

.h-100 {
  height: 100px;
}

.h-100pr {
  height: 100% !important;
}

.h-100vh {
  height: 100vh;
}

// Width
.w-10 {
  width: 10px;
}

.w-15 {
  width: 15px;
}

.w-20 {
  width: 20px !important;
}

.w-25 {
  width: 25px !important;
}

.w-30 {
  width: 30px;
}

.w-35 {
  width: 35px;
}

.w-40 {
  width: 40px !important;
}

.w-45 {
  width: 45px;
}

.w-50 {
  width: 50px;
}

.w-55 {
  width: 55px;
}

.w-60 {
  width: 60px;
}

.w-65 {
  width: 65px;
}

.w-70 {
  width: 70px;
}

.w-75 {
  width: 75px;
}

.w-80 {
  width: 80px;
}

.w-85 {
  width: 85px;
}

.w-90 {
  width: 90px;
}

.w-95 {
  width: 95px;
}

.w-100 {
  width: 100px;
}

.w-100pr {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

// Font
.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-italic {
  font-style: italic;
}

.fw-3 {
  font-weight: 300 !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.fw-5 {
  font-weight: 500 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.fw-7 {
  font-weight: 700 !important;
}

// margin
.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0px;
}

.m-2 {
  margin: 2px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-8 {
  margin: 8px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.m-60 {
  margin: 60px;
}

.m-70 {
  margin: 70px;
}

.m-80 {
  margin: 80px;
}

.m-90 {
  margin: 90px;
}

.m-100 {
  margin: 100px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-100 {
  margin-left: 100px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* Scroll  */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--primarySM-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primarySM-color);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primaryLG-color);
}