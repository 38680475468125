.header-logo{
  object-fit: contain;
  @media (min-width: 320px) {
    width: 120px;
  }
  @media (min-width: 370px) {
    width: 160px;
  }
  @media (min-width: 768px) {
    width: 180px;
  }
}