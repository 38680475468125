.status {
  font-size: 12px;
  width: fit-content;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  text-transform: uppercase;
  letter-spacing: 0.5pt;
}

.br-right-sec {
  border-right: 1px solid var(--grayXS-color);
}

a {
  color: inherit;

  &:hover {
    color: var(--primary-color);
  }
}

// Colors
.primary-color {
  color: var(--primary-color) !important;
}

.hove-primary:hover {
  color: var(--primary-color) !important;
}

.secondary-color {
  color: var(--secondary-color) !important;
}

.dark-color {
  color: var(--dark-color) !important;
}

.success-color {
  color: var(--success-color) !important;
}

.white-color {
  color: var(--white-color) !important;
}

.gray-color {
  color: var(--gray-color) !important;
}

.grayXS-color {
  color: var(--grayXS-color);
}

.error-color {
  color: var(--error-color) !important;
}

.grayMD-color {
  color: var(--grayMD-color);
}

.warning-color {
  color: var(--warning-color);
}

.info-color {
  color: var(--info-color);
}

// Background Colors
.bg-inherit {
  background-color: transparent !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-success {
  background-color: var(--success-color) !important;
}

.bg-successXS {
  background-color: var(--successXS-color) !important;
}

.bg-primaryXS {
  background-color: var(--primaryXS-color) !important;
}

.bg-primarySM {
  background-color: var(--primarySM-color) !important;
}

.bg-whiteLG {
  background-color: var(--whiteXL-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-error {
  background-color: var(--error-color) !important;
}

.bg-errorSM {
  background-color: var(--errorT-color) !important;
}

.bg-gray {
  background-color: var(--gray-color);
}

.bg-grayXS {
  background-color: var(--grayXS-color);
}

.bg-black {
  background-color: var(--black-color);
}

.bg-warning {
  background-color: var(--warning-color);
}

.bg-warningT {
  background-color: var(--warningT-color);
}

.bg-primaryT {
  background-color: var(--primarySM-color);
}

.shadow-none {
  box-shadow: none !important;
}

.shadowsm {
  box-shadow: rgba(0, 123, 255, 0.09) 5px 5px 10px !important;
}

.shadow-primary {
  box-shadow: var(--shadow-primary) !important;
}

.max-txt-400 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
}

html {
  scroll-behavior: smooth;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
}