.header_container {
  padding: 5px;
  background-color: var(--primary-color);
  box-shadow: var(--shadow-primary);

  .MuiAvatar-root {
    height: 34px;
    width: 34px;
    object-fit: cover;
    border: 1px solid var(--white-color);
  }

  #site_logo {
    width: 110px;
    object-fit: cover;
  }
}