.navbar_container {
   position: relative;
   z-index: 2;

   .navbar {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      width: max-content;
      background: var(--primary-color);
      box-shadow: var(--shadow-primaryMD);
      backdrop-filter: blur(3px);
      border: 1px solid var(--primarySM-color);
      border-radius: 30px 30px 0px 0px;
      display: flex;
      gap: 10px;
      justify-content: space-between;

      min-width: 320px;
      padding: 12px 20px 6px 20px;

      @media (max-width: 374px) {
         min-width: 250px;
      }

      .active {
         color: var(--white-color) !important;
      }
   }
}