.container-body {
  position: relative;
  width: 100%;
}

.arrow-inside {
  margin: 0px -50px 0px -50px;
}

.dot-inside {
  position: absolute;
  margin-top: -20px;
}

.btns {
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (min-width: 980px) {
    display: none;
  }
}

.dot {
  width: 32px;
  height: 9px;
  border-radius: 30px;
  margin: 0 4px;
  background: var(--primaryMD-color);
  transition: 0.3s;

  &:hover,
  &.active {
    cursor: pointer !important;
    background: var(--primary-color);
    width: 50px;
  }
}

.btn {
  border: none;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: var(--primarySM-color);
  color: var(--primary-color);
  backdrop-filter: blur(1px);
  &:hover {
    cursor: pointer;
    background-color: var(--primaryMD-color);
  }
}

@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

.slide {
  opacity: 0;
  position: absolute;
  transition: opacity ease-in-out 0.4s;
}
.active-anim {
  opacity: 1;
}
