.appbar {
  border: none !important;

  .appbar-container {
    padding: 0px;
    border: 1px solid var(--primarySM-color);
    background: var(--whiteMD-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 10px;
    min-width: 300px;

    .appbar-inner-div {
      padding: 0px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.sidebar {
  margin: 10px;

  .sidebar-container {
    border: 1px solid var(--primarySM-color);
    background: var(--whiteMD-color);
    backdrop-filter: blur(20px);
    box-shadow: var(--shadow-primarySM);
    border-radius: 8px;
    color: var(--primary-color);

    .sidebar-card {
      background-color: var(--primaryXS-color);
      padding: 10px;
      display: flex;
      align-items: center;
      margin: 15px 20px 12px 0px;
      border-radius: 0px 15px 15px 0px;
    }
  }
}