.unit-widget {
  .unit-widget-img {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      border-radius: 8px 8px 0px 0px;
      object-fit: cover;
      width: 100%;
      height: 220px;
      border-bottom: 1px solid var(--grayXS-color);
    }

    .unit-hover-action {
      background-color: rgba(0, 0, 0, 0.37);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transition: all 0.3s ease;
      border-radius: 10px 10px 0px 0px;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      .view-unit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60%;
      }
    }
  }

  .unit-widget-body {
    .unit-widget-deatil {
      display: flex;
      align-items: center;
      padding: 15px;
    }
  }
}
